/* Remove the .Clippy wrapper and adjust global styles */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #181818;
  color: #FFFFFF;
}

.App {
  text-align: left;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;

}

.logo-header {
  padding-left: 20px;
}

#natural-logo {
  font-size: 22px;
  color: #FFFFFF;
}

.nav-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #FFFFFF;
  border-radius: 6px;
}

.icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-container {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #181818;
  border: 1px solid #474747;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 6px 6px;
  min-width: 210px;
  z-index: 1000;
  border-radius: 12px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px;
  border: none;
  background-color: #181818;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 6px;
}

.dropdown-menu button:hover {
  background-color: #282828;
}

.dropdown-menu button.highlighted {
  background-color: rgba(255, 108, 61, 0.1);
  box-shadow: 0 0 0 2px rgba(255, 108, 61, 0.5);
  transition: all 0.3s ease-out;
}

/* Remove the absolute positioning for highlighted menu items */
.dropdown-menu button.highlighted {
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
  z-index: auto;
  display: flex;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #282828;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF6C3D;
  box-shadow: 0 0 10px rgba(255, 108, 61, 0.3);
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.contact-link {
  margin-left: 10px;
}

.contact-link a {
  color: #DCDCDC;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
}

.contact-link a:hover {
  color: #FFFFFF;
}

.hero {
  display: flex;
  align-items: center;
  padding: 60px 40px;
  max-width: 1100px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .text-content,
  .image-container {
    width: 100%;
    max-width: 100%;
  }
}

.text-content {
  max-width: 550px;
  text-align: left;
  flex: 1;
}

#header {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 12px;
}

.highlight {
  color: #FF6C3D;
  text-shadow: 0 0 10px rgba(255, 108, 61, 0.3);
}

#sub-header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #DCDCDC;
}

.email-form {
  display: flex;
  padding: 10px;
  background-color: #2C2C2C;
  border-radius: 8px;
  overflow: hidden;
}

.email-form-2 {
  display: flex;
  padding: 12px;
  background-color: #2C2C2C;
  border-radius: 8px;
  overflow: hidden;
}

.input-button-group {
  display: flex;
  width: 100%;
  overflow: hidden;
}

#email, #email-2 {
  flex: 1;
  font-size: 18px;
  padding: 12px 16px;
  border: none;
  background-color: #2C2C2C;
  color: #FFFFFF;
  cursor: text;
  outline: none;
}

#email-2 {
  background-color: #2C2C2C;
}

/* For Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #2C2C2C inset;
  box-shadow: 0 0 0px 1000px #2C2C2C inset;
  -webkit-text-fill-color: #FFFFFF;
  transition: background-color 5000s ease-in-out 0s;
}

/* For other browsers */
input,
input:focus {
  outline: none;
}

#email::placeholder,
#email-2::placeholder {
  color: #888888;
}

.input-button {
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  background-color: #FF6C3D;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  box-shadow: 
    inset 0px 1px 0px rgba(255, 255, 255, .2),
    0 0 15px rgba(255, 108, 61, 0.3);
  border-radius: 100px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  max-width: 100%;
}

.background-image {
  max-width: 450px;
  width: 100%;
  pointer-events: none;
  user-select: none;
}

.features {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 30px;
}

.how {
    display: flex;
    display: row;
    gap: 30px;
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 30px;

}


.founders {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 0 10px;
}

.story {
  max-width: 800px; /* Make the story section narrower */
  margin: 0 auto;
  padding: 5px 0px;
  margin-bottom: 60px;
}

#story-heading {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 46px;
  margin-top: 0px;
  color: #FFFFFF;
  text-align: center;
}

#story-heading .highlight {
  color: #FF6C3D;
}

#story-text {
  font-size: 18px;
  line-height: 1.6;
  color: #DCDCDC;
  margin-bottom: 32px;
  text-align: left;
}

/* Add styles for square bullet points */
.story ul {
  list-style-type: none;
  padding-left: 20px;
  line-height: 1.6;
  color: #DCDCDC;
  font-size: 18px;
  margin-bottom: 46px;
}

.story ul li {
  position: relative;
  padding-left: 20px; 
  color: #DCDCDC;
  line-height: 1.6;
  margin-bottom: 10px;
  font-size: 18px;
}

.story ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #FF6C3D;
}

.story ul li strong {
  font-weight: 700;
  color: #FFFFFF;
}

.features {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 30px;
}

#features-text {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 46px;
  color: #FFFFFF;
  text-align: center;
}

.features-section{
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1100px;

  margin-bottom: 80px;
}

.features-2 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.feature-item-2 {
  flex: 1;
  min-width: 0; /* This allows the flex item to shrink below its content size */
  text-align: left;
  padding: 28px;
  background-color: #282828;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

#icon {
  width: 50px; /* Set a fixed width */
  height: auto; /* Maintain aspect ratio */
  align-self: flex-start; /* Align the icon to the start of the flex container */
}

@media (max-width: 768px) {
  .features-2 {
    flex-direction: column;
  }

  .feature-item-2 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .features-section {
    padding: 12px 15px;
  }

  .feature-item-2 {
    padding: 20px;
  }

  #feature-heading {
    font-size: 16px;
  }

  #feature-text {
    font-size: 14px;
  }

  #icon {
    width: 40px;
    height: auto;
  }
}

.main-features {
  text-align: center;
  margin-bottom: 60px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
}


.feature-item {
  font-family: 'Inter', sans-serif;
  flex: 1;
  text-align: left;
  padding: 28px;
  background-color: #181818;
  border: 1px solid #474747;
  border-radius: 8px;
}

#feature-heading {
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  margin-top: 6px;
  color: #FFFFFF;
}

#feature-text {
  font-size: 18px;
  line-height: 1.5;
  color: #DCDCDC;
  margin-top: -4px;
  margin-bottom: 0px;
}

#icon {
  width: 50px;
  margin-bottom: 10px;
  pointer-events: none;
  user-select: none;
}

footer {
  padding: 40px 0;
  background-color: #181818;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #474747;
}

.inner-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 24px;
}

a {
  color: #FF6C3D;
  text-decoration: none;
  text-shadow: 0 0 5px rgba(255, 108, 61, 0.3);
}

#logo-text {
  text-align: center;
  font-weight: 500;
  color: #DCDCDC;
  font-size: 18px;
  margin-bottom: 24px;
}

.logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 56px;
  margin-top: 32px;
  margin-bottom: 56px;
}

.trusted-by-text {
  text-align: center;
  color: #DCDCDC;
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: bold;
}

.logo-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

#leapyear {
  width: auto;
  height: 35px;
  pointer-events: none;
  user-select: none;
  filter: brightness(0) invert(1);
}

#pioneer {
  width: auto;
  height: 75px;
  pointer-events: none;
  user-select: none;
  filter: brightness(0) invert(1);
}

#icon {
  width: auto;
  height: 24px;
}

/* Media Queries for Very Small Screens */
@media (max-width: 400px) {
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
  
  .logo-header {
    padding-left: 0px;
    flex: 1;
    text-align: left;
  }
  
  .hero {
    flex-direction: column;
    padding: 30px 10px;
  }

  .text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  #header {
    font-size: 32px;
  }

  #sub-header {
    font-size: 18px;
  }

  .image-container {
    justify-content: center;
  }

  .background-image {
    max-width: 100%;
  }

  .features {
    flex-direction: column;
    gap: 20px;
  }

  .feature-item {
    padding: 20px;
  }

  .logos {
    gap: 20px;
  }

  #mit {
    width: auto;
    height: 30px;
    pointer-events: none;
    user-select: none;
  }
  
  #texas {
    width: auto;
    height: 85px;
    pointer-events: none;
    user-select: none;
  }

  .inner-footer {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  #texas {
    height: 50px;
  }

  #mit {
    height: 20px;
  }

  .input-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .input-button {
    padding: 10px 15px;
    font-size: 16px;
  }

  #email, #email-2 {
    padding: 10px;
    font-size: 16px;
  }

  .contact-link a {
    color: #8F8F8F;
    text-decoration: none;
    padding-right: 0px;
  }
}

/* General Tablet Style */
@media (min-width: 401px) and (max-width: 1024px) {
  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  #header {
    font-size: 36px;
  }

  #sub-header {
    font-size: 20px;
  }

  .image-container {
    justify-content: center;
  }

  .background-image {
    max-width: 80%;
  }

  .features {
    flex-direction: column;
    gap: 30px;
  }

  .feature-item {
    padding: 25px;
  }

  .logos {
    gap: 40px;
  }

  #texas {
    height: 60px;
  }

  #mit {
    height: 20px;
  }

  .inner-footer {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .footer-logo {
    margin-bottom: 15px;
  }

  .input-button-group {
    flex-direction: column;
    gap: 15px;
  }

  .input-button {
    padding: 10px 20px;
    font-size: 18px;
  }

  #email, #email-2 {
    padding: 15px;
    font-size: 18px;
  }

  .contact-link a {
    padding-right: 15px;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 30px 20px;
  }

  .text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 30px;
  }

  #header {
    font-size: 36px;
  }

  #sub-header {
    font-size: 20px;
  }

  .image-container {
    width: 100%;
    justify-content: center;
  }

  .features {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .feature-item {
    width: 100%;
  }

  .features-2 {
    flex-direction: column;
    gap: 20px;
  }

  .feature-item-2 {
    width: 100%;
  }

  .how {
    flex-direction: column;
    gap: 20px;
  }

  .logos {
    flex-direction: column;
    gap: 30px;
  }

  #leapyear, #pioneer {
    height: auto;
    width: 80%;
    max-width: 200px;
  }

  .story {
    padding: 20px;
  }

  #story-heading {
    font-size: 32px;
  }

  .input-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .input-button {
    width: 100%;
  }

  .inner-footer {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .footer-logo {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .input-email-form-2 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .App-header,
  main,
  footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero {
    padding: 20px 0;
  }

  .text-content {
    padding: 0 10px;
  }

  .feature-item,
  .feature-item-2 {
    padding: 15px;
  }

  #header {
    font-size: 28px;
  }

  #sub-header {
    font-size: 16px;
  }

  #features-text,
  #story-heading {
    font-size: 24px;
  }

  .input-button-group {
    flex-direction: column;
  }

  .input-button {
    margin-top: 10px;
    width: 100%;
  }

  #email, #email-2 {
    width: 100%;
    box-sizing: border-box;
  }
}

/* Add these new styles at the end of your file */
@media (max-width: 768px) {
  .App-header,
  main,
  footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header-content {
    padding: 15px 0;
  }

  .hero {
    padding: 30px 0;
  }

  .features,
  .founders,
  .main-features,
  .how,
  .story {
    padding-left: 0;
    padding-right: 0;
  }

  .feature-item,
  .feature-item-2 {
    padding: 20px;
  }

  .logos {
    flex-direction: column;
    align-items: center;
  }

  #leapyear,
  #pioneer {
    max-width: 80%;
    height: auto;
  }

  .inner-footer {
    padding: 20px 0;
  }

  .footer-logo {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .input-email-form-2 {
    width: 100%;
  }

  .email-form,
  .email-form-2 {
    padding: 10px;
  }

  .input-button-group {
    flex-direction: column;
  }

  .input-button {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .App-header,
  main,
  footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .feature-item,
  .feature-item-2 {
    padding: 15px;
  }

  #header {
    font-size: 32px;
  }

  #sub-header {
    font-size: 18px;
  }

  #features-text,
  #story-heading {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 30px 0;
  }

  .text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 30px;
  }

  #header {
    font-size: 36px;
  }

  #sub-header {
    font-size: 20px;
  }

  .image-container {
    width: 100%;
    justify-content: center;
  }

  .features {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }

  .feature-item {
    width: 100%;
    padding: 20px;
  }

  .features-2 {
    flex-direction: column;
    gap: 20px;
  }

  .feature-item-2 {
    width: 100%;
  }

  .how {
    flex-direction: column;
    gap: 20px;
  }

  .logos {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  #leapyear, #pioneer {
    height: auto;
    width: 80%;
    max-width: 200px;
  }

  .story {
    padding: 20px 0;
  }

  #story-heading {
    font-size: 32px;
  }

  .input-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .input-button {
    width: 100%;
  }

  .inner-footer {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }

  .footer-logo {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  .input-email-form-2 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .header-content {
    padding: 10px 15px;
  }

  #header {
    font-size: 28px;
  }

  #sub-header {
    font-size: 18px;
  }

  #feature-heading {
    font-size: 20px;
  }

  #feature-text {
    font-size: 16px;
  }

  #story-heading {
    font-size: 28px;
  }

  #story-text {
    font-size: 16px;
  }

  .story ul li {
    font-size: 16px;
  }
}

.icon-button.highlighted,
.dropdown-menu button.highlighted,
.toggle-container.highlighted,
#copy-link.highlighted,
#menu-button.highlighted,
#email.highlighted,
.features.highlighted,
.founders.highlighted,
.contact-link a.highlighted {
  background-color: rgba(255, 108, 61, 0.1);
  box-shadow: 0 0 0 2px rgba(255, 108, 61, 0.5), 0 0 10px rgba(255, 108, 61, 0.3);
  border-radius: 6px;
  transition: all 0.3s ease-out;
}

.fading {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.close-tooltip {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

/* Add this at the end of your Clippy.css file */
#features-tooltip {
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
}

.icon-button svg,
.dropdown-menu button svg,
.contact-link a svg {
  color: #8F8F8F;
  transition: color 0.3s ease;
}

.icon-button:hover svg,
.dropdown-menu button:hover svg,
.contact-link a:hover svg {
  color: #FFFFFF;
}

/* Increase icon size for mobile and tablet */
@media (max-width: 1024px) {
  .icon-button {
    font-size: 24px;
    width: 48px;
    height: 48px;
  }
}

/* Ensure menu and tooltips are at the front */
.dropdown-menu {
  z-index: 1000;
}

.react-tooltip {
  z-index: 1001 !important;
}

@media (max-width: 1024px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .nav-actions {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .react-tooltip {
    max-width: 80vw !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

/* Add or modify these media queries at the end of your CSS file */
@media (max-width: 480px) {
  .header-content {
    padding: 10px 15px;
  }

  .nav-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .icon-button {
    font-size: 18px;
    width: 36px;
    height: 36px;
  }

  .contact-link a {
    font-size: 14px;
  }

  #natural-logo {
    font-size: 18px;
  }
}

/* Add these styles for the icons */
.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor; /* This ensures the SVG takes the color of its parent */
}

@media (max-width: 768px) {
  .icon-button svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .icon-button svg {
    width: 16px;
    height: 16px;
  }
}

  @media (max-width: 360px) {
    .header-content {
      padding: 10px;
    }

    .nav-actions {
      gap: 5px;
    }

    .icon-button {
      font-size: 16px;
      width: 32px;
      height: 32px;
    }

    .contact-link a {
      font-size: 12px;
    }

    #natural-logo {
      font-size: 16px;
    }
  }

  .icon-button {
    /* ... existing styles ... */
    color: #FFFFFF; /* Ensure the icon color is white */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-button svg {
    width: 20px;
    height: 20px;
    fill: currentColor; /* This ensures the SVG takes the color of its parent */
  }

  @media (max-width: 768px) {
    .icon-button svg {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 480px) {
    .icon-button svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 768px) {
    .header-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      width: 100%;
      box-sizing: border-box;
    }

    .logo-header {
      padding-left: 0;
    }

    .nav-actions {
      margin-top: 0;
      gap: 10px;
      display: flex;
      align-items: center;
    }

    .icon-button {
      width: 32px;
      height: 32px;
      font-size: 18px;
    }

    .contact-link {
      margin-left: 0;
    }

    .contact-link a {
      font-size: 14px;
      white-space: nowrap;
    }

    /* Ensure the menu is visible */
    .menu-container {
      display: flex;
    }

    .dropdown-menu {
      right: 0;
      left: auto;
      z-index: 1003;
    }
  }

  /* Add or modify these media queries at the end of your CSS file */
  @media (max-width: 480px) {
    .header-content {
      padding: 10px 15px;
    }

    .nav-actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .icon-button {
      font-size: 18px;
      width: 36px;
      height: 36px;
    }

    .contact-link a {
      font-size: 14px;
    }

    #natural-logo {
      font-size: 18px;
    }
  }

  @media (max-width: 360px) {
    .header-content {
      padding: 10px;
    }

    .nav-actions {
      gap: 5px;
    }

    .icon-button {
      font-size: 16px;
      width: 32px;
      height: 32px;
    }

    .contact-link a {
      font-size: 12px;
    }

    #natural-logo {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .inner-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
    }

    .footer-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;
    }

    .contact-link {
      margin: 0 5px;
    }

    .contact-link a {
      font-size: 14px;
      padding: 5px 10px;
      white-space: nowrap;
    }

    .input-email-form-2 {
      width: 100%;
    }

    .email-form-2 {
      flex-direction: column;
    }

    #email-2 {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    .inner-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
    }

    .footer-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;
    }

    .contact-link {
      margin: 0 5px;
    }

    .contact-link a {
      font-size: 14px;
      padding: 5px 10px;
      white-space: nowrap;
    }

    .input-email-form-2 {
      width: 100%;
    }

    .email-form-2 {
      flex-direction: column;
    }

    #email-2 {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    .inner-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
    }

    .footer-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;
    }

    .contact-link {
      margin: 0 5px;
    }

    .contact-link a {
      font-size: 14px;
      padding: 5px 10px;
      white-space: nowrap;
    }

    .input-email-form-2 {
      width: 100%;
    }

    .email-form-2 {
      flex-direction: column;
    }

    #email-2 {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    .inner-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
    }

    .footer-logo {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;
    }

    .contact-link {
      margin-left: 0;
      margin-bottom: 0;
    }

    .contact-link a {
      font-size: 14px;
      padding: 5px 10px;
    }

    .input-email-form-2 {
      width: 100%;
    }

    .email-form-2 {
      flex-direction: column;
    }

    #email-2 {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    .inner-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
    }

    .footer-logo {
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
    }

    .contact-link {
      margin-left: 0;
      margin-bottom: 10px;
    }

    .contact-link a {
      font-size: 14px;
    }

    .input-email-form-2 {
      width: 100%;
    }

    .email-form-2 {
      flex-direction: column;
    }

    #email-2 {
      margin-bottom: 10px;
    }
  }

/* Styles that need to be global can be left outside the .Clippy wrapper */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.small-grey-text {
  color: #888888;
  font-size: 16px;
}
