.demo-container {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 24px;
  max-width: 400px;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.demo-header {
  display: flex;
  gap: -4px;
  margin-bottom: 10px;
}

.demo-content {
  padding: 10px 20px 20px; /* Increased bottom padding */
  background-color: #434343;
  font-size: 18px;
  border-radius: 10px;
}

#demo-section-header {
  font-size: 18px;
  font-weight: 600;
  color: #DCDCDC;
  margin: 0px;
}

.demo-problem-text {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.demo-suggestion-header {
  display: flex;
  color: #DCDCDC;
  font-weight: 400;
  justify-content: space-between;
  align-items: center;
}

.demo-actions {
  display: flex;
}

.demo-accept, .demo-reject {
  background-color: transparent;
  border: none;
  padding: 8px;
  width: 36px; /* Adjust this value to make the button square */
  height: 36px; /* Adjust this value to make the button square */
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
  color: #DCDCDC;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 7px;
}

.demo-accept:hover, .demo-reject:hover {
  background-color: rgba(255, 255, 255, 0.1); /* 10% white overlay on hover */
}

.demo-accept:active, .demo-reject:active {
  scale: .95;
}

.demo-sources {
  display: flex;
  margin-top: 20px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #555 #434343; /* Changed to match content background */
  padding-bottom: 1px;
}

.demo-sources::-webkit-scrollbar {
  height: 6px; /* Height of the scrollbar */
}

.demo-sources::-webkit-scrollbar-track {
  background: #434343; /* Changed to match content background */
}

.demo-sources::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Roundness of the scrollbar thumb */
}

.demo-source {
  display: inline-flex;
  align-items: center;
  background-color: #434343;
  border: 1px solid #575757;
  border-radius: 100px;
  padding: 12px 16px;
  font-size: 16px;
  margin-right: 10px;
  flex-shrink: 0;
  transition: background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); /* Changed to 0 blur */
  position: relative; /* Add this */
  z-index: 1; /* Add this */
}

.demo-source:hover {
  background-color: #575757;
}

.demo-source:active {
    scale: .95;
}

.source-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

#demo-suggestion-text {
    margin-top: 4px;
    font-size: 18px;
    margin-bottom: 10px;
}

#icon-demo {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

@media (max-width: 480px) {
  .demo-container {
    padding: 16px;
  }

  .demo-content {
    padding: 10px 15px 15px;
  }

  #demo-section-header {
    font-size: 16px;
  }

  .demo-problem-text {
    font-size: 16px;
  }

  #demo-suggestion-text {
    font-size: 16px;
  }

  .demo-source {
    padding: 8px 12px;
    font-size: 14px;
  }

  #icon-demo {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
}
